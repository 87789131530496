import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('src/app/front/views/layout/layout.component').then(m => m.LayoutComponent),
    },
    {
        path: '**',
        redirectTo: '',
    },
];
