import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WindowScrollService {
    private scrollSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public getScroll(): BehaviorSubject<number> {
        return this.scrollSubject;
    }

    public setScroll(scroll: number): void {
        this.scrollSubject.next(scroll);
    }
}
