import { Component, HostListener } from '@angular/core';
import { PathService } from './locale/services/path.service';
import { RouterLink, RouterOutlet } from '@angular/router';
import { WindowScrollService } from './core/services/window-scroll.service';
import { WindowResizeService } from './core/services/window-resize.service';
import { VisibilityService } from './core/services/visibility.service';

@Component({
    selector: '#app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [
        RouterOutlet,
        RouterLink,
    ],
})
export class AppComponent {
    public constructor(
        private readonly windowScrollService: WindowScrollService,
        private readonly windowResizeService: WindowResizeService,
        private readonly visibilityService: VisibilityService,
        private readonly pathService: PathService,
    ) {
        this.windowScrollService.setScroll(window.scrollY);
        this.windowResizeService.setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    @HostListener('window:scroll', ['$event'])
    public onScroll(): void {
        this.windowScrollService.setScroll(window.scrollY);
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        this.windowScrollService.setScroll(window.scrollY);
        this.windowResizeService.setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    @HostListener('window:visibilitychange', ['$event'])
    public onWindowVisible(): void {
        this.visibilityService.setWindowVisible(!document.hidden);
    }

    public getUrl(path: string): string {
        return this.pathService.getUrlForPath(path);
    }
}
