import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WindowSize } from '../api/window-size';

@Injectable({
    providedIn: 'root'
})
export class WindowResizeService {
    private sizeSubject: BehaviorSubject<WindowSize> = new BehaviorSubject<WindowSize>({
        width: 0,
        height: 0
    });

    public getSize(): BehaviorSubject<WindowSize> {
        return this.sizeSubject;
    }

    public setSize(size: WindowSize): void {
        this.sizeSubject.next(size);
    }
}
