import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './routes';
import { environment } from 'src/environments/environment';
import { provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        {
            provide: LOCALE_ID,
            useValue: environment.locale.id
        },
        importProvidersFrom([
            BrowserAnimationsModule,
        ]),
        provideClientHydration(withNoHttpTransferCache()),
        provideHttpClient(withFetch())
    ]
};
