import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VisibilityService {
    private isWindowVisible: boolean = true;

    public setWindowVisible(visible: boolean): void {
        this.isWindowVisible = visible;
    }

    public getWindowVisible(): boolean {
        return this.isWindowVisible;
    }
}
