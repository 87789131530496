import { Injectable } from '@angular/core';
import { PathGroup } from '../api/path-group';
import { Paths } from '../models/paths';

@Injectable({
    providedIn: 'root'
})
export class PathService {
    public static getRouterPath(path: string): string {
        const pathParts: string[] = path.split('.');

        let resultUrl: string = '',
            paths: PathGroup | string = Paths;

        for (let part of pathParts) {
            if (typeof paths === 'string' || paths[part] === undefined) {
                return '/'
            }

            resultUrl = this.getPartPath(paths[part]);
            paths = paths[part];
        }

        return resultUrl;
    }

    private static getPartPath(path: string | PathGroup): string {
        if (typeof path === 'string') {
            return path;
        }

        return path.base;
    }

    public getUrlForPath(path: string): string {
        const pathParts: string[] = path.split('.');

        let resultUrl: string = '',
            paths: PathGroup | string = Paths;

        for (let part of pathParts) {
            if (typeof paths === 'string' || paths[part] === undefined) {
                return '/'
            }

            resultUrl += '/' + PathService.getPartPath(paths[part]);
            paths = paths[part];
        }

        return resultUrl;
    }
}
